import React from "react";
import { Typography } from "../../common/Typography/Typography";
import Img from "gatsby-image";
// import { buildImageObj } from "../../../lib/helpers";
// import { imageUrlFor } from "../../../lib/image-url";

import styles from "./About.module.scss";
import { Title } from "../../common/Title/Title";
import BlockText from "../../content/block-text";

const About = ({ title, body, mainImage }) => {
  return (
    <section className={styles.intro}>
      <div className={styles.introInner}>
        <Typography tagName="h1" type="heading" className={styles.mainContent}>
          {title}
        </Typography>
        <div className={styles.image}>
          {mainImage && mainImage.asset && (
            <Img fluid={mainImage.asset.fluid} alt={mainImage.alt} />
          )}
        </div>
        <section className={styles.mainContent}>
          <BlockText blocks={body || []} />
        </section>
      </div>
    </section>
  );
};

export { About };
