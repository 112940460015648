import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/content/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { About } from "../components/pages/About/About";

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      aboutTitle
      _rawDescription
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          fluid(maxWidth: 1440) {
            ...GatsbySanityImageFluid
          }
        }
        alt
      }
      keywords
    }
  }
`;

const AboutPage = ({ data, errors, location }) => {
  if (errors) {
    return (
      <Layout location={location}>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout location={location}>
      <SEO title={site.aboutTitle} description={site.description} keywords={site.keywords} />

      <About title={site.aboutTitle} body={site._rawDescription} mainImage={site.mainImage} />
    </Layout>
  );
};

export default AboutPage;
